.Report_master{
    height: auto;
    position: relative;
    width: 50rem;
    /* border: 1px solid var(--ProjectColor); */
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    gap: 14px;
    justify-content: flex-start;
    flex-direction: column;
}
.report_table {
    border-collapse: collapse;
    width: 100%;
  }
  .customMultiSelect{
    width: 160px;
    background-color: aqua !important;
  }
  .dropdown-container{
    position: absolute;
    top: 0px;
    right: 500px;
    left: 0;
    width: 161px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  .dropdown-content{
    
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 20px !important;
    
  }
.Report_master1{
    display: flex;
    align-items: center;
    justify-content: center;
}

.report_table thead {
    height: 15px;
    border: 2px 0px solid var(--ProjectColor) !important;
    background-color: rgb(216, 222, 228);
    width: 100%;
    overflow-x: auto;

}


.Report_records{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.report_head ul{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
}

.list_report{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    list-style: none;
    font-size: 10px;
    font-weight: 300;
}
.report_table{
    width: 100%;

}
.report_table td, th{
    font-size: 12px;
    padding: 8px;
    text-align: start;
    border-bottom: 1px solid #ddd;
}
.Register_btn_con_barcode{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.Inpterpretation_head p{
    display: flex;
    width: 100%;
    /* justify-content: ; */
    font-size: 15px;
    font-weight: bolder;
    /* border-bottom: 2px solid black; */
    align-items: center;
    color: rgba(0, 0, 0, 0.26);
}
.Interpretation_Detail{
    width: auto;
    font-size: 15px;

}
.new_report_address{
    margin-left: 10px;
    
}
.report_qr{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
}
.Register_btn_con_QRcode{
    margin-right: 20px;
    padding-top: 10px;
    box-sizing: border-box;
}
.Register_btn_con_Doc_sign{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
}
.Register_btn_con_Doc_sign p{
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottomcode{
    position: absolute;
    align-items: flex-end;
    height: 150px;
    bottom: 10px;

}
.interpretation_container{
    margin-top: 10px;
}