

::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Remove blue color on hover for specific navigation items */
/* Remove blue color on hover for specific navigation items */
.tab-bax-classes-remarks:hover,
.tab-bax-classes-reference:hover,
.tab-bax-classes-interpretation:hover,
.tab-bax-classes-commercial:hover {
background-color: transparent !important;
color: black !important;
text-decoration: none !important;
}

/* CSS for Custom Highlight Toggle Button */
.custom-highlight-toggle {
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
width: 20px; /* Adjust the width to make the button smaller */
height: 20px;
background-color: #ccc; /* Background color when off */
border-radius: 20px;
position: relative;
cursor: pointer;
}

.custom-highlight-toggle:checked {
  height: 20px;
  width: 39%;
background-color: var(--ProjectColor); /* Background color when on */
}


.chk_box_23{
  width: 170px !important;
  height: 20px !important ;
 
}

.css-1aquho2-MuiTabs-indicator{

  background-color: var(--labelcolor) !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--projectwhite) !important;
}

.gap_ad_clr3{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}


/* Styling for the Selected Medicine Table */
.Selected-table-container {
  width: 100%;
  margin-top: 20px;
  overflow-x: auto; 
}

.selected-medicine-table2 {
  width: 100%;
  /* border-collapse: collapse; */
}

.selected-medicine-table2 th {
  background-color: var(--ProjectColor);
  color: var(--labelcolor);
  padding: 7px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
}

.selected-medicine-table2 th,
.selected-medicine-table2 td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  
}

/* Styling for table body rows */
.selected-medicine-table2 tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.selected-medicine-table2 tbody tr:hover {
  background-color: #ddd;
}


.icon_flx_bilg5{
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}


.custom-highlight-toggle::before {
content: '';
width: 18px;
height: 18px;
border-radius: 50%;
background-color: white;
position: absolute;
top: 1px;
left: 1px;
transition: 0.3s;
}

.custom-highlight-toggle:checked::before {
transform: translateX(10px); /* Adjust the position of the handle when checked */
}


