


.case_sheet_consent {
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    max-width: 800px;
    background-color: #f9f9f9;
  }
  
  .case_sheet_consent h1,
  .case_sheet_consent h3 {
    text-align: center;
  }
  
  .case_sheet_consent .jee1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
  }
  
  .case_sheet_consent .jee1 label {
    width: 180px;
    font-weight: bold;
  }
  
  .case_sheet_consent .jee1 input,
  .case_sheet_consent .jee1 textarea {
    width: calc(100% - 200px);
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .case_sheet_consent .RegisForm_1_consent_consent_radiooo_head35r {
    display: flex;
    gap: 20px;
  }
  
  .case_sheet_consent .RegisForm_1_consent_consent_radiooo {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  /* .case_sheet_consent .consent_consent_radiooo_inputt {
    gap: 20px;
  }
   */
  .case_sheet_consent .RegisForm_1_consent_consent_radiooo label {
    margin: 0;
  }
  
  .case_sheet_consent .summadiv {
    margin: 20px 0;
  }
  



.jjj p{
    display: flex;
    justify-content: flex-start;

}


 hr{
width: 280px;
position: relative;
left: 190px;


}

.jjdiv{
    display: flex;
    justify-content: space-between;
    width: 650px;
}
.edwuedy47y{
    display: flex;
    width: 355px!important;
    height: 50px;
    padding: 5px;
}
.summadiv1{
    display: flex;
    justify-content: space-between !important;
    width: 650px !important;
}
.eeiuujeuwu39 input{
    width: 120px;
    padding: 5px;
    border: none;
    outline: none;
    border-bottom: 1px solid var(--ProjectColor);
}

.summadiv{
    display: flex;
    justify-content: flex-start ;
}
.RegisForm_1_consent_consent_ppp {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    margin: 0; /* remove default margin */
}

.RegisForm_1_consent_consent_ppp input {
    margin-right: 5px; /* Adjust spacing between inputs and text */
}





.edewdce{
    border: 1px solid var(--ProjectColor);
    border-radius: 5px;
    padding: 5px;
    /* width: 500px; */
    /* margin-left: 65px; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */

}

.jwhyw66w4{

    display: flex;
    justify-content: space-around;
    align-items: center;
    }

.sdsdsxds{
    display: flex;
    justify-content: space-around;
}

.jee1 {
    display: flex;
    justify-content: flex-start; /* Aligns items to the left */
    align-items: center;
    text-align: left; /* Aligns text to the left */
    gap: 50px;
    margin: 10px;
}

.jee1 label{
    width: 100px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    font-size: var(--fontsize);
    font-weight: bold;

}

.jee1 input, .jee1 textarea{
    width: 420px;
    border: none;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    }


    .jee1 .RegisForm_1_consent_consent_radiooo_head35r {
        display: flex;
        gap: 40px;
    }
    
    .jee1 .RegisForm_1_consent_consent_radiooo {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    
    .jee1 .consent_consent_radiooo_inputt {
        width: auto; /* Radio buttons don't need a fixed width */
    }
    
    .jee1 .RegisForm_1_consent_consent_radiooo label {
        font-weight: normal;
        font-size: var(--fontsize);
    }


.shdywy656230{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin: 10px;
}

.shdywy656230 label{
    width: 100px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    font-size: var(--fontsize);
    font-weight: bold;

}

.kkkk input{
    width: 20px;
    border: none;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    }

.kkkk {
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin: 10px;
}

.kkkk label{
    width: 100px;
    display: flex;
    justify-content: space-between;
   
    font-size: var(--fontsize);
    font-weight: bold;

}

.shdywy656230 input{
width: 120px;
border: none;
padding: 5px;
border-radius: 5px;
outline: none;
}

@media print {
    .RegisForm_1_consent_consent_ppp{
        margin-left: 50px;
        width: 85%;
    }

    .wiiue876{
        margin-left: 72px;
        width: 50% !important;
    }

    .uwytywe6262309{
        display: flex;
        gap: 30px;
    }

    .edewdce{
        border: 1px solid var(--ProjectColor);
        border-radius: 5px;
        padding: 5px;
        /* width: 500px; */
        margin-left: 50px;
        /* display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; */
    
    }
    .dssxxsssa{
        margin-top: 50px;
    }
}
