/* .Total_input_container{
    width: 100%;
    display: flex;
    gap: 20px 15px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px ;
    box-sizing: border-box;

} */
.Total_input_container_patientData{
    width: 100%;
    display: flex;
    gap: 20px 15px;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 10px ;
    box-sizing: border-box;
}
.Total_input_container{
  width: 100%;
  display: flex;
  gap: 20px 15px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 10px ;
  box-sizing: border-box;
}

.inp_container_all_intakeoutput label{
    width: 120px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--labelcolor);
    font-size: 15px;
    font-weight: 600;
}
.inp_container_all,.inp_container_all_patientData,.inp_container_all_intakeoutput{
    width: auto;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
    align-items: center;
}
.inp_container_all_patientData span{
    width: auto;
    color: gray;
    align-items: center;
    font-size: 14px;
}
.inp_container_all label{
    width: 120px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--labelcolor);
    font-size: 15px;
    font-weight: 600;
}
.inp_container_all_patientData label{
    width: auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--labelcolor);
    font-size: 15px;
    font-weight: 600;
}
.inp_container_all input{
    width: 100px;
    border: 1px solid var(--ProjectColor);
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
    overflow:hidden ;
    text-overflow: ellipsis;
}
.inp_container_all_intakeoutput input{
    width: 140px;
    border: 1px solid var(--ProjectColor);
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
    overflow:hidden ;
    text-overflow: ellipsis;
}
.inp_container_all_intakeoutput select{
    background-color: var(--selectbackgroundcolor);
    width: 150px;
    border: 0px;
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
    overflow:hidden ;
    text-overflow: ellipsis;
}
.inp_container_all select{
    background-color: var(--selectbackgroundcolor);
    width: 110px;
    border: 0px;
    border-radius: 6px;
    height: 27px;
    outline: 0px;
    padding: 0px 5px;
    align-items: center;
    font-size: 14px;
    overflow:hidden ;
    text-overflow: ellipsis;
}
.inp_container_all input:focus{
    border: 1px solid rgba(0, 0, 0, 0.87);
}


.Timeselectorr {
    width: 200px;
    background-color: #fff;
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns with equal width */
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .Timeselectorr span {
    cursor: pointer;
    width: 23px;
    height: 23px;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .Timeselectorr span:hover {
    background-color: #0e76ec;
  }
  .Timeselected {
    background-color: #83a0ee;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
  }
  .Timeselected:hover{
    background-color: #696865;
  }
  .Timeenabled{
    background-color: rgba(218, 226, 228, 0.705);
    border-radius: 5px;
    color: #000;
    cursor: pointer;
  }
  .Timeenabled:hover{
    background-color: #91b2bd !important;
  }
  .summasearchid{
    position: absolute;
    left: 260px;
    
  }
  .DrugAdministrationTable {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  .DrugAdministrationTable table {
    width: auto;
    border-collapse: collapse;
    border-radius: 10px;
    box-shadow: 1px 1px 5px 2px #c4b9b9;
    height: auto;
    padding: 0px 10px;
    box-sizing: border-box;
  }
  
  .DrugAdministrationTable table thead {
    color: var(--ProjectColor);
    width: max-content;
  }
  .DrugAdministrationTable table thead tr th{
    width: max-content;
    padding:  5px;
    box-sizing: border-box;
  }
  .DrugAdministrationTable th,
  .DrugAdministrationTable td {
    padding: 0px 38px;
    height: auto;
    text-align: center;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
  }
  .DrugAdministrationTable table tbody tr{
    height: 30px;
  }
  .DrugAdministrationTable tr:nth-child(even) {
    background-color: rgba(224, 221, 223, 0.384);
  }
  
  .DrugAdministrationTable tr:hover {
    background-color: #5b595917;
  }
  .qwertyuio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
  }


/* ----------------------------------------- */


  
  /* Create a custom checkbox */
  .check_box_clrr {
    
    height: 25px;
    width: 25px;
    object-fit: contain;
    display: grid;
    place-items: center;
    background-color: transparent;
  }
  
 .check_box_clrr_1{
  color: green !important;
 }
 .check_box_clrr_2{
  color: orange !important;
 }
 .check_box_clrr_3{
  color: purple !important;
 }
 .check_box_clrr_4{
  color: red !important;
 }
 .check_box_clrr_5{
  color: red !important;
 }
  .check_box_clrr_cancell{
    color: orangered !important;
  }
  .check_box_clrr_cancell:hover{
    color: red !important;
  }
  
 


.total_con_bil_summacreatepanna{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 5px;
  box-sizing: border-box;

}
.summacreatepanna{
  text-align: end;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 250px;
}
.summacreatepanna label{
font-size: 13px;
font-weight: 600;
width: 150px;
display: flex;
justify-content: space-between;
}
.summacreatepanna span{
  font-size: 12px;
  color: grey;
}