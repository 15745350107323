.medical_history_container{
    width: 100%;
}
.ques_title_t{
    width: 100%;
    height: 30px;
    display: flex;
    margin-top: 20px;
    color: rgb(134, 3, 134);
    font-size: 20px;
    font-weight: bolder;
    border-bottom: 1px solid rgb(134, 3, 134);
    height: 45px;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.answer_c{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
    padding: 5px;
}

.past-prst-flx{
    display: flex;
    flex-direction: row;
}
.answer_c label{
    text-align: center;
    font-size: 15px;
    color: gray !important;
    font-weight: bold;
    margin-right: 8px;
    
}
.split-line{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}
.enquery{
    display: flex;
    width: 99%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}
.enquery1{
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}
.label-ques{
    width: 240px ;
}
.ques_title_t1{
    font-weight: 500;
    margin: 20px 0px 20px 0px;
    font-size: 20px;
    width: 100%;
    color: rgb(134, 3, 134);
    align-items: center;
    display: flex;
    justify-content: center;
}
.questions1{
    display: flex;
    align-items: center;
    justify-content: center;
}
.ques textarea{
    border: 2px solid grey;
    margin-bottom: 25px;
    width: 392px; 
    height: 40px;
}
.summary-qus{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(134, 3, 134);
}
.summary{
    border-top: 1px solid gray;
    border-left: 1px solid grey;
    border-right: 1PX solid gray;
    width: 50%;
    padding: 8px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.summary1{
    border-bottom: 1px solid gray;
    border-left: 1px solid grey;

    border-top: 1px solid grey;
    width: 50%;
    height: 35px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sub_title_t{
    font-size: 20px;
    font-weight: 600;
    width: 350px;
    color: grey;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    display: flex;
    justify-content: center;
}
.answer_ci{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 75%;
}
.ques{
    display: flex;
    width: 400px ;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid  rgb(134, 3, 134);
    padding: 10px;
}
.ques1{
    display: flex;
    width: 210px ;
    justify-content: space-between;
    /* margin-left: 15px; */
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid rgb(134, 3, 134);
}
.ques2 {
    display: flex;
    width: 380px ;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
}
.ques2 label{
    color: rgb(134, 3, 134);
    font-weight: bold;
}
 input[type="checkbox"]{
    width: 18px;
    height: 18px;
}
input[type="checkbox"]:checked::after{
    background-color: rgb(130, 130, 236);
}
.submition{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
.split_screen{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.submit{
    width: 70px;
    padding: 5px;
    border-radius: 10px;
    background-color: rgb(134, 3, 134);
    outline: none;
    border: 0;
    cursor: pointer;
}
.submit:hover{
    background-color: hsl(22, 87%, 49%);
}
.input_lables{
    width: 210px !important;
    font-weight: 600 !important;
    font-size: 15px;
    color: rgb(134, 3, 134);
    word-wrap: break-word;
}
.input_txt{
    /* outline: none; */
    /* border: none; */
    width: 100px;
    padding: 5px;
    height: 10px;
    margin: 5px;
    border-radius: 5px;
    border-color: rgb(134, 3, 134);
    /* border-bottom: 1px solid grey; */
}
input::placeholder {
    text-align: right;
    color: rgb(134, 3, 134);
}
.date-input{
    border: none;
    margin-left: 20px;
}

.past-prst-alncnt{
    /* border: none !important; */
    /* outline: none !important; */
    display: flex;
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* border-bottom: transparent !important; */
}

@media screen and (max-width:920px){

    .enquery{
        display: flex;
        align-items: center;
        justify-content: center;
        word-wrap: break-word;
        width: 100%;
    }  
    .ques{
        width: 450px;
    }

    .summary{
        border: none;
        display: flex;
        width: auto;
        justify-content: space-between;
    }
    .split-line{
        flex-direction: column;
    }

    .ques_title_t1{
        flex-direction: column;
        width: auto;
    }


    .past-prst-alncnt{
        /* border: none !important; */
        /* outline: none !important; */
        display: flex;
        width: 250px;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border-bottom: transparent !important; */
    }

}
@media screen and (max-width:500px){
    .enquery{
        display: flex;
        align-items: center;
        justify-content: center;
        word-wrap: break-word;
        width: auto;
    }

    
    .ques{
        width: 270px;
    }
    .summary-qus{
        flex-direction: column;
        align-items: center;
        display: flex;
        
    }
    .summary{
        border: none;
        display: flex;
        width: auto;
        justify-content: space-between;
    }

    .summary1{
        border-bottom: 1px solid gray;
        border-left: 1px solid grey;
        border-top: 1px solid grey;
        border-right: 1px solid grey;
        width: 50%;
        height: 45px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
    .split-line{
        flex-direction: column;
    }
    .ques_title_t1{
        flex-direction: column;
        width: auto;
    }
    .submition{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ques1{
       
        width: 190px ;
        
    }

    .past-prst-alncnt{
        /* border: none !important; */
        /* outline: none !important; */
        display: flex;
        width: 225px;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border-bottom: transparent !important; */
    }
}