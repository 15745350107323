.RateCardtype_array_master{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    row-gap: 30px;
    padding: 5px;
    box-sizing: border-box;
    /* background-color: #fff7ed; */
}
.RateCardtype_array_master_sub{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    padding: 10px;
  
    box-sizing: border-box;
    font-size: var(--fontsize);
}
.RateCardtype_array_master_sub_div{
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 15px;

}

.RateCardtype_array_master_sub_div label{
font-size: 18px;
}
.RateCardtype_array_master_sub_div1{
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    gap: 20px;
    border: 1px solid #b0b0b0;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 15px 10px;
}
.op_ratecharge_div{
    width: 355px;
    background: #8fffa9;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    height: auto;
    box-shadow: 0px 2px 10px rgba(0, 0, 2, 3);
}
.op_ratecharge_div label{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 15px;
    font-weight: bold;
}
.op_ratecharge{
    width: 100%;
    background-color: #f5ebeb;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 5px 0px;
    box-sizing: border-box;
    border-radius: 5px;
}
.op_ratecharge label{
    width: 100%;
    font-size: 13px;
    font-weight: bold;
    padding: 6px;
}

.op_ratecharge input{
    width: 150px;
    padding: 5px;
    text-align: center;
    border: 1px solid var(--ProjectColor);
    border-radius: 5px;
    outline: none;
    height: 15px;

}
.op_ratecharge_con{
    width: 100%;
    background-color: #f5ebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px
}
.op_ratecharge_con label{
    display: flex;
    justify-content: space-between;
    text-align: start;
    width: 75px;
    font-size: var(--fontsize);
    font-weight: bold;
 

}
.op_ratecharge_con input{
    width: 70px;
    padding: 5px;
    text-align: center;
    border: 1px solid var(--ProjectColor);
    border-radius: 5px;
    outline: none;
    height: 15px;
}

.op_ratecharge_con_1{
    width: 90%;
    background-color: #f5ebeb;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content:flex-end;
    gap: 5px;
}

@media (max-width: 680px) {

    .RateCardtype_array_master_sub{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        width: 100%;
    }


}