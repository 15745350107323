
table {
    border-collapse: collapse;
    width: 50%;
    margin: 20px auto;
    font-family: Arial, sans-serif;
}
th, td {
    border: 1px solid #b9b2b2;
    text-align: center;
    padding: 8px;
}
th {
    background-color: #f2f2f2;
    font-weight: bold;
}
tr:nth-child(even) {
    background-color: #f9f9f9;
}
tr:hover {
    background-color: #f1f1f1;
}









.ffff {
    display: flex;
    align-items: center;
    padding-left: 150px;
}


.aaa {
    display: flex;
    align-items: center;
    padding-left: 150px;
}



.checkbox-label {
    display: flex;
    align-items: center;
    
}


.textarea-wide1{
    width: 250px; /* Adjust the width as needed */
    height: 60px;
}

.checkbox-label1 {
   
    padding-left: 34px;
}

.form-field56 {
    padding-left: 35px;
}

.health-habits-container {
    display: flex;
    justify-content: space-between;
  }
  .women-section, .men-section {
    width: 48%;
  }




.textarea-wide {
    width: 800px; /* Adjust the width as needed */
    height: 60px;
  }
  .RegisForm_2{
    display: flex;
    justify-content: center;
    gap: 10px;
  }
.RegisForm_2 label {
    width: 120px;
    display: flex;
    font-size: var(--fontsize);
    font-weight: bold;
    justify-content: space-between;
    text-align: start;
    align-items: center;
    gap: 10px;
    color: var(--labelcolor);
}


.assmet_cbg{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    text-align: center;
    /* gap: 25px; */
    justify-content: center;
}

.ass_chdgtt{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
}

.ass_pocd label{
    display: flex;
    width: 120px !important;
    justify-content: space-between;
    font-size: var(--fontsize);
    font-weight: bold;
}

.wdvw_yd input{
    font-size: 20px;
    width: 20px !important;
    height: 16px !important;
}

.wdvw_yd label{
    width: 30px !important;
}



.checkbox-group {
    display: block;
  }
  
  .checkbox-group input[type="checkbox"],
  .checkbox-group label {
    display: block;
    margin-bottom: 5px; /* Add margin between checkbox groups */
  }
  

  @media (max-width: 1260px) {
    .assmet_cbg{
        display: flex;
        flex-direction: column;
        row-gap: 10px;justify-content: center;
        align-items: center;
        text-align: center;
    }
  }