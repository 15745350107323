
.RegisFormcon_consent_consent{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    
    /* row-gap: 5px; */
}

.RegisForm_1_consent_consent{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 7px;
    width: 100%;
    
}


.RegisForm_1_consent_consent label{
    width: 260px;
    display: flex;
    justify-content: space-between;
    color: var(--labelcolor);
    font-weight: bold;
    font-size: var(--fontsize);
    text-align: start;
    align-items: center;
}

.RegisForm_1_consent_consent input{
    width: 350px;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--ProjectColor);
    padding: 5px;


}

.RegisForm_1_consent_consent_radiooo{
    display: flex;
    gap: 0px;
    justify-content: flex-start;
    align-items: center;
    text-align: center; 
    width: 60px; 
}

.RegisForm_1_consent_consent_radiooo label{
    font-size: var(--fontsize);
    font-weight: bold;
    width: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 5px;
    align-items: center;
    text-align: center;

    
}

.transgender98{
    width: 30px !important;
}


.consent_consent_radiooo_inputt{

    width: 30px !important;
    height: 13px;
}

.RegisForm_1_consent_consent textarea{
    width: 350px;
    padding: 5px;
    height: 50px;

}

.RegisForm_1_consent_consent_p{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    row-gap: 14px;
}

.RegisForm_1_consent_consent_p p{
    display: flex;
    font-size: var(--fontsize);

    font-weight: bold;
    color: var(--labelcolor);
    width: 100% ;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    align-items: center;
}
.sigCanvas2_head11{
    display: flex;
    justify-content: center;
}
.sigCanvas2_head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 50%;
}

.sigCanvas2{
    border: 1px solid var(--ProjectColor);
}
.RegisForm_1_consent_consent_radiooo_head35r{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    width: 360px;

}

.transgender98{
    width: 75px !important;
}

.female4eed{
    width: 75px;
}

.female4d{
    width: 60px;
}


.case_sheet_consent{
    margin: 0 auto; 
    width: 50%;
    /* height: 100%; */
  }

  
@media print {

    /* .appointment {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;

    } */

    /* body * {
        visibility: hidden;
      }
      #reactprintcontent, #reactprintcontent * {
        visibility: visible;
      }
      #reactprintcontent {
        position: absolute;
        left: 0;
        top: 0;
      } */

     
    /* .no-print {
    display: none !important;
    } */

    @page {
      size: A4;
      margin: 0;
      padding: 0;
      top: 20mm;
      left: 20mm;
    }

    body {
        margin: 0; /* Ensure no margin on the body */
      }
    .header_container_sidebar{
        display: none !important;
    }
    .printgr5 {
      display: none !important;
    }

    .case_sheet_consent{
        width: 100% !important;
        margin-top: 0px !important;
        /* margin-right: 40px !important; */
        margin-left: -40px !important;
        page-break-inside: avoid;
    }

    .RegisForm_1_consent_consent_p p{
        width: 80% !important;
    }

    .RegisterForm_1_btns{
        display: none;
    }
}


@media screen and (max-width: 768px) {
    .RegisForm_1_consent_consent
    {
        width: 300px;
    }

.RegisForm_1_consent_consent_radiooo{
    width: 70px;
    gap: 3px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.transgender98{
    width:70px !important;
}
.female4d{
    width: 50px !important;
    gap: 5px;
}

.female4eed{
    width: 80px;
    gap: 5px;
}

    .RegisForm_1_consent_consent label{
        width: 120px;
    }

    .case_sheet h4{
        width: 100% !important;
    }

    .sigCanvas2_head{
        margin-left: 0px;
    }

    .RegisForm_1_consent_consent_p p{
        width: 275px;
    }
}