.personal_information{
    box-shadow: 0 2px 12px rgba(32,32,32,.3);
    width:45%;
    right: 10%;


}
.test_information{
    box-shadow: 0 2px 12px rgba(32,32,32,.3);
    width:45%;
    
}
.mastercontainer_for_patient{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content:space-between;
}

.samplecapture_component{
    display: flex;
    place-items: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.patientdetails_lab{
    display: flex;
    width: auto;
    text-align: center;
    justify-content: space-between;
}
.patientdetails12{
    display: flex;
    width: 90%;
    /* align-items: center; */
    justify-content: space-between;
    /* margin-bottom: 50px; */
}
.patientdata{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 340px;
    gap: 1rem;
}
.phelobotomist_head{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    gap: 3px;
    width: auto;
    /* width: px; */
}
.logo_profile{
    font-size: 25px;

}


.Capture_Status_select{
    width: 130px;
    height: 30px;
    border-radius: 8px;
    cursor: pointer;
}
.samplecapture_table_for_phelobotomist{
    width: 100%;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Capture_Status_select1{
    width: 130px;
    height: 30px;
    border-radius: 8px;
    text-align: center;
}
.table_for_plebotomist_sample{
    width: 90%;
    border-collapse: collapse;
    border-radius: 10px;
    overflow: hidden;
    /* border: 2px solid var(--ProjectColor); */
    
    /* overflow: hidden; */
}

.table_for_plebotomist_sample th{
    background-color: var(--ProjectColor);
    /* width: auto; */
    text-align: center;
    height: 25px;
    padding: 5px;
    /* border-radius: 10px; */
    border: 1px solid var(--projectwhite);
}
.table_for_plebotomist_sample .trends{
    font-size: 23px;
    color: red;
    cursor: pointer;
}   
.table_for_plebotomist_sample td{
    text-align: center;
    height: 25px !important;
    /* padding: 3.5px; */
    padding: 1.5px;
    border: 1px solid rgb(218, 214, 214);
}
.remarktable{
    width: 100%;
    text-align: center;
}
.remarktable th{
    width: 50%;
    text-align: center;
}
.remarktable tr{
    width: 50%;
    text-align: center;
    /* display: flex; */
    /* gap: 2px; */
    /* display: grid; */
    /* place-items: center; */
}

/* .table_for_plebotomist_sample tr:nth-child(even){
    background-color: rgb(206, 206, 196);
} */
.table_textarea{
    width: 100%;
    height: 26px;
    border: none;
    box-shadow: none;
    outline: none;
}
  


@media (max-width: 1024px){
   
    .patientdetails_lab{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .patientdata{
        width: 20rem;
        display: flex;
        justify-content: space-between;
    }
    .Capture_Status_select{
        width: 120px;
        height: 30px;
        border-radius: 8px;
        margin: 5px;
        cursor: pointer;
    }
    .patientdetails_lab {
        display: flex;
        flex-direction: column; 
        width: 100%; 
      }
      
      .patientdata {
        display: flex;
        align-items: center; 
        justify-content: flex-start; 
        margin-bottom: 10px;
      }
      
      .patientdata span, .patientdata label {
        margin-right: 10px; 
      }
      
      .phelobotomist_head {
        display: flex;
        align-items: center; 
        white-space: nowrap; 
      }
      
      .phelobotomist_head span {
        margin-right: 5px;
      }
      .samplecapture_table_for_phelobotomist{
        width: 100%;
        /* display: block; */
        
      }
}

@media (max-width: 750px){
    .samplecapture_table_for_phelobotomist{
        width: 100%;
        /* display: block; */
        /* overflow-x: auto;
        margin: 10px; */
      }
    .table_for_plebotomist_sample{
        overflow: auto;
        display: block;
    }
}
@media (max-width: 550px){
    .samplecapture_table_for_phelobotomist{
        width: 100%;
        /* display: block; */
        /* overflow-x: auto;
        margin: 10px; */
      }
    .table_for_plebotomist_sample{
        overflow: auto;
        display: block;
    }
}