.query textarea{
    height: 45px;
    width: 220px;
    outline: 0px;
    padding: 5px;
    border: 0px;
    margin-left: 10px;
    box-shadow: 1px 1px 5px 5px rgb(212, 229, 229);
    background-color: transparent;
}
.split-screen{
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-top: 10px;
}
.query span{
    font-size: 20px;
    font-weight: bolder;
    color: gray;
}
.input_lable{
    width: 235px;
    font-weight: 600;
    font-size: 15px;
    color: rgb(134, 3, 134);
    word-wrap: break-word;
    margin: 10px;
}
.query{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px;
    word-wrap: break-word;
    border-bottom: 1px solid rgb(134, 3, 134);
}
input::placeholder{
    text-align: left;
    color: gray;
    font-weight: lighter;
}
.qus-input{
    width: 220px;
    border: none;
    height: 25px;
    font-size: 15px;
    padding: 5px;
    outline: rgb(212, 229, 229);
}
.note{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.frst-btn-vst{
    margin: 20px 10px;
}

.note p{
    color: red;
}
.abserve{
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width:1000px){
    .split-screen{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .query{
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        word-wrap: break-word;
        border-bottom: 1px solid rgb(134, 3, 134);
    }
    .input_lable{
        width: 300px ;
        font-weight: 600;
        font-size: 15px;
        color: rgb(134, 3, 134);
        word-wrap: break-word;
    }
    .query textarea{
        height:45px;
        width: auto;
        outline: 0px;
        padding: 5px;
        border: 0px;
        margin-left: 10px;
        margin: 10px;
        box-shadow: 1px 1px 5px 5px rgb(212, 229, 229);
        background-color: transparent;
    }
}

@media screen and (max-width:534px){
    .query{
        width: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        word-wrap: break-word;
        border-bottom: 1px solid rgb(134, 3, 134);
    } 
}