#panel1bh-header{
    color: rgb(134, 3, 134) !important;
    font-size: 19px !important;
    text-align: left;
    position: relative;
    z-index: 0;
    /* margin-bottom: 5px; */
}


.req-qus-container1{
    width: 100%;
    border-bottom: 1px solid rgb(134, 3, 134);

}
.req-qus{
    width: 93%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 10px;
}

.req-qus112{
    width: 50%;
    display: flex;
    align-items: center;
    padding: 15px;
}
.req-qus1{
    width: 23% !important;
    display: flex;
    align-items: center;
    margin-right:40px;
}
.req-qus1 input,
.req-qus31 input,
.req-qus12 input{
  flex: 2;
  border: none;
  border-bottom: 0px solid #333;
  outline: none;
  padding: 5px;
  width: 90px;
  font-size: 13px;
}
.new-custom-form-row1 input {    
    flex: 2;
    border: none;
    border-bottom: 0px solid #333;
    outline: none;
    padding: 5px;
    width: 108px;
    font-size: 13px;
  }
  .new-patient-info-container1,
  .new-patient-contact-container1 {
    border-bottom: 1px solid rgb(134, 3, 134);
    display: flex;
  
    /* flex: 2; */
  } 
.new-custom-form-row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    /* position: relative; */
    padding: 10px;
    /* width: auto !important; */
  }
.new-custom-form-row1 {
    width: 23%;
  }
  .input-sech-fld input{
    padding: 10px;
  }

.req-qus3 input{
    border: none;
    outline: none;
    width: 180px;
    font-size: 13px;
    padding-left: 10px;
}
.req-qus2{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 10px 10px 0px;    
}
.req-qus2 label{
    font-weight: bold;
    font-size: 15px;
    width: 160px;
}
.req-qus3{
    display: flex;
    width: 50%;
    margin-left: 20px;
}

.req-qus31{
    width: 95% !important;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 13px;
    margin-left: 20px;
}
.check1{
    display: flex;
    width: 100%;
    font-size: 13px;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.req-qus12{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.check-box{
    display: flex;
    align-items: center;
    width: 30px;
    justify-content: space-between;
    gap: 5px;
}
.req-check-box-pre{
    display: flex;
    align-items: center;
    width: 100px ;
    justify-content: space-between;
    margin-right: 20px;
}
.new-data-check-label{
    flex: 3;
    font-weight: bold;
    margin-right: 0px;
    text-align: start;
    font-size: 14px;
    width: 250px;
    color: rgb(134, 3, 134);
}
.Register_btn_con1{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}
.table_container1_preg1{
    width: 100%;
    display: grid;
    place-items: center;
    overflow-x: scroll;
}
.Register_btn_con1 button{
    width: 70px;
    height: 35px;
    background-color: rgb(134, 3, 134);
    border: none;
    border-radius: 10px;
    cursor: pointer;
}
.Register_btn_con1 button:hover{
    background-color:rgb(134, 3, 134);
}
.check-box label{
    color: grey;
    font-size: 15px;
    font-weight: bold;
}
.req-check-box-pre input[type="checkbox"]{
    width: 15px !important;
    height: 15px !important;
    cursor: pointer;
}
.check12{
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: space-between;
    margin-left: 40px;
}
.req-qus3 input[type="checkbox"]{
    width: 15px;
    height: 15px;
    cursor: pointer;
}
.check label{
    color: gray !important;
    cursor: pointer;
    font-size: 13px;
}
.new-custom-label-title1{
    width: 165px;
}
.check{
    display: flex;
    width: 50px;
    align-items: center;
    justify-content: space-between;
}
.req-qus3 label{
    margin-left: 5px;
    color: rgb(134, 3, 134);
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
}
.req-qus1 label{
    color: rgb(134, 3, 134);
    font-weight: bold;
    font-size: 14px;
}
.req-qus1 span{
    font-size: 18px;
    font-weight: bolder;
    color: gray;
}
.req-qus2 label{
    color: rgb(134, 3, 134);
    font-weight: bold;
    font-size: 14px;
    width: 120px;
}
.req-qus2 span{
    font-size: 18px;
    font-weight: bolder;
    color: gray;
}
.table_container1_preg{
  max-height: 400px; /* Adjust the max height as needed */
  overflow-y: auto;
}
.req-qus31 label{
    padding-left: 10px;
}
.new-custom-input-phone-cycle1{
    width: 100px;
}
.input_table_preg{
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  overflow: scroll;
}
.qus{
    margin-right: 20px;
}
.input_table_preg th{
    background-color: rgb(134, 3, 134);
    border: 1px solid #ddd;
  
    padding: 8px;
    text-align: center;
    border-radius: 5px;
}
.input_table_preg td{
    border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}
.input-sech-fld1{
    padding: 5px;
}
.input-sech-fld1 textarea{
    width: 300px;
    height: 40px;
}
.new-custom-label-title1 {
    flex: 2;
    font-weight: bold;
    margin-right: 0px;
    text-align: start;
    font-size: 14px;
    width: 250px;
    color: rgb(134, 3, 134);
  
  }
.input-sech-fld1 input:focus{
    outline:2px solid rgb(134, 3, 134);
    border: none;
}
.input-sech-fld1 input{
    height: 20px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  @media screen and (max-width:1270px){
    .pregnancy-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .req-qus1 input{
        width: 180px;
    }
    .req-quss{
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
        border: none;
        margin-right: 10rem;
    }
  .new-patient-info-container1,
  .new-patient-contact-container1 {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 0px !important;
    font-size: 14px;
    font-weight: bold;
    width: 400px;

  }
  .req-qus-container1{
    width: 400px !important;
    border: none !important;

}
    .new-custom-form-row1 {
        font-size: 14px;
        font-weight: bold;
        border-bottom: 1px solid rgb(134, 3, 134);
        width:600px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .req-qus{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 400px !important ;
        border: none;    
        padding: 0px;
    }
    .new-custom-input-phone-cycle{
        width: 120px ;
    }
    .req-qus1{
        width: 400px !important;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(134, 3, 134);
        margin-left: 40px;
    }
    .req-qus label{
        word-wrap: break-word;
        width: 100px ;
    }
    .req-quss label{
        word-wrap: break-word;
        width: 100px ;
        font-size: 14px;
    font-weight: bold;
    }
    .req-qus2{
        width: 420px ;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .req-check-box-pre{
        display: flex;
        align-items: center;
        width: 280px;
        gap: 20px;
        justify-content: flex-start;

    }
    .check1{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 320px ;
        justify-content: center;
    }
    .req-qus3{
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .qus{
        width: 280px;
        display: flex;
    }
    .req-qus31{
        width: 320px !important;
    }
    .table_container1_preg{
        width: 800px ;
        overflow-x: scroll;
        display: grid;
        place-items: center;

    }
    .req-qus3 input[type="checkbox"]{
        width: 18px;
        height: 18px;
    }
    .input_table_preg {
        width: 100%;
        overflow-x: scroll;
    }

    .input_table_preg th,
    .input_table_preg th,
    .input_table_preg td,
    .input_table_preg td {
        padding: 12px;
        font-size: 12px;
    }
    .input-sech-fld{
        padding: 10px;
    }



  }
  @media screen and (max-width:800px){
    .pregnancy-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .req-qus112{
        display: none;
    }
    .req-qus-container1{
        width: 320px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .new-custom-label-title1 {
        flex: none;
        width: 175px;
      }
    .new-data-check-label {
        flex: none;
        width: 175px;
      }  
    .req-quss1{
        display: flex;
        align-items: center;
        width: 290px;
        justify-content: center;
        border: none;
    }
    .new-patient-info-container1,
    .new-patient-contact-container1 {
      display: flex;
      flex: 0 !important;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 0px !important;
    }
    .new-custom-form-row1 {
        font-size: 12px;
        border-bottom: 1px solid rgb(134, 3, 134);
        width:400px;
    
        
      }
   
    .req-qus{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 290px ;
        border: none;    
        padding: 0px;
    }
    .new-custom-input-phone-cycle{
        width: 120px !important;
    }
    .req-qus1{
        width: 100% !important;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(134, 3, 134);
        margin-left: 40px;
    }
    .req-qus label{
        word-wrap: break-word;
        width: 100px ;
    }
    .req-quss label{
        word-wrap: break-word;
        width: 100px ;
    }
    .req-qus2{
        width: 420px ;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .check1{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 310px !important;
        justify-content: center;
    }
    .req-qus3{
        width: 320px;
    }
    .qus{
        width: 280px;
        display: flex;
    }
    .req-qus31{
        width: 320px !important;
    }
    .table_container1_preg{
        width: 550px ;
        overflow: scroll;
    }
    .req-qus3 input[type="checkbox"]{
        width: 18px;
        height: 18px;
    }
    .input_table_preg {
        width: 100%;
        overflow-x: scroll;
    }

    .input_table_preg th,
    .input_table_preg th,
    .input_table_preg td,
    .input_table_preg td {
        padding: 8px;
        font-size: 12px;
    }
  }

  @media screen and (max-width:570px){
   .pregnancy-container{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
   }
    .req-qus{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 290px !important ;
        border: none;    
    }
    .new-custom-label-title1 {
        flex: none;
        width: 100px;
        word-wrap: break-word;
      }
    .new-data-check-label{
        flex: none;
        width: 100px;
        word-wrap: break-word;
    }  
    .new-patient-info-container1,
    .new-patient-contact-container1 {
      display: flex;
      flex: 0 !important;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 0px !important;
      width: 290px;  

    }
    .req-qus1{
        width: 290px !important ;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 40px;
        
    }
    .req-qus112{
        display: none;
    }
    .req-quss label{
        word-wrap: break-word;
        width: 100px ;
    }
    .req-qus label{
        word-wrap: break-word;
        width: 100px ;
    }
    .req-qus2{
        width: 290px !important;
        display: flex;
        flex-direction: column;
    }
    .check1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 40px;
    }
    .req-qus-container1{
        width: 290px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .req-qus31{
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .table_container1_preg{
        width: 290px ;
        overflow: scroll;
    }
    .input_table_preg {
        width: 100%;
        overflow-x: scroll;
    }

    .input_table_preg th,
    .input_table_preg td
    {
        padding: 8px;
        font-size: 12px;
    }
    .req-quss1{
        display: flex;
        align-items: center;
        width: 290px;
        justify-content: center;
        border: none;
    }
    
    .new-custom-form-row1 {
        font-size: 12px;
        border-bottom: 1px solid rgb(134, 3, 134);
        width:290px;
        justify-content: space-between;
        
      }

  }

